// Buttons

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary-dark1);
    --bs-btn-hover-border-color: var(--bs-primary-dark2);
    --bs-btn-focus-shadow-rgb: 125, 82, 168;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-primary-dark2);
    --bs-btn-active-border-color: var(--bs-primary-dark3);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-outline-primary {
    --bs-btn-color: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: 102, 51, 153;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--bs-primary);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--bs-primary);
    --bs-gradient: none;
}

.theme-dark .btn-primary {
    --bs-btn-color: #000;
    --bs-btn-hover-color: #000;
    --bs-btn-active-color: #000;
}

.btn-primary {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color);
}

.btn-primary:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

.btn-primary:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-active-shadow);
}

.btn-primary:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow);
}

.btn-outline-primary {
    color: var(--bs-btn-color);
    border-color: var(--bs-btn-border-color);
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary.active {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

.btn-close {
    color: #000000 !important;
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

// Form Control

.form-control:hover {
    border-color: var(--bs-primary);
}

.form-control:focus {
    border-color: var(--bs-primary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
}

// Table 
.table> :not(:first-child) {
    border-top: 1px solid transparent;
}

.nav-link {
    color: #9e9e9e;
    border: 1px solid transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary);
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-primary);
}