.UI-Editor {
    .Editor-textarea {
        border: 1px solid #515151;
        border-radius: 0.375rem;
        background-color: #222;
        color: #b1b1b1;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;

        &:hover {
            border-color: var(--bs-primary);
        }

        &.focused {
            border-color: var(--bs-primary);
            box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
        }

        .DraftEditor-editorContainer {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-family: var(--theme-text-font), "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
                    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
                    "Helvetica Neue", sans-serif;
                color: #b1b1b1;
            }
        }
    }
}