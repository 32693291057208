#root {
    height: 100vh;
    overflow: hidden;

    .Guru {
        height: 100vh;
        font-size: 16px;
        background-color: #222;
        color: #e1e1e1;
        overflow: scroll;

        &[data-theme-mode=light] {
            background-color: #FFF;
            color: #222;
        }

        .Guru-not-initialized {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: center;
            align-items: center;
            font-size: 1.4em;

            span {
                font-size: 3em;
            }
        }

        .Guru-not-authenticated {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: center;
            align-items: center;
            font-size: 1.4em;

            span {
                font-size: 3em;
            }
        }
    }
}