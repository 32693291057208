// Text colors
// ——————————————————————————————————————————

.text-blue {
    color: var(--bs-blue) !important;
}

.text-purple {
    color: var(--bs-purple) !important;
}

.text-green {
    color: var(--bs-green) !important;
}

.text-orange {
    color: var(--bs-orange) !important;
}

.text-yellow {
    color: var(--bs-yellow) !important;
}

.text-red {
    color: var(--bs-red) !important;
}

.text-pink {
    color: var(--bs-pink) !important;
}

.text-indigo {
    color: var(--bs-indigo) !important;
}

.text-grey {
    color: var(--bs-gray-900) !important;
}

.text-gray {
    color: var(--bs-gray-600) !important;
}

.text-default {
    color: black !important;
}

.theme-dark .text-default {
    color: white !important;
}


.text-theme {
    color: var(--bs-primary);
}

.text-theme-darker {
    color: var(--bs-primary-dark1);
}

.text-theme-darker2 {
    color: var(--bs-primary-dark2);
}

.text-theme-darker3 {
    color: var(--bs-primary-dark3);
}

.text-theme-darker4 {
    color: var(--bs-primary-dark4);
}

.text-theme-lighter {
    color: var(--bs-primary-light1);
}

.text-theme-lighter2 {
    color: var(--bs-primary-light2);
}

.text-theme-lighter3 {
    color: var(--bs-primary-light3);
}

.text-theme-lighter4 {
    color: var(--bs-primary-light4);
}




.bg-blue {
    background-color: var(--bs-blue) !important;
}

.bg-purple {
    background-color: var(--bs-purple) !important;
}

.bg-green {
    background-color: var(--bs-green) !important;
}

.bg-orange {
    background-color: var(--bs-orange) !important;
}

.bg-yellow {
    background-color: var(--bs-yellow) !important;
}

.bg-red {
    background-color: var(--bs-red) !important;
}

.bg-pink {
    background-color: var(--bs-pink) !important;
}

.bg-indigo {
    background-color: var(--bs-indigo) !important;
}

.bg-gray {
    background-color: var(--bs-gray-600) !important;
}

.bg-gray-300 {
    background-color: var(--bs-gray-300) !important;
}

.bg-default {
    background-color: black !important;
}

.theme-dark .bg-default {
    background-color: white !important;
}


.bg-theme {
    background-color: var(--bs-primary);
}

.bg-theme-darker {
    background-color: var(--bs-primary-dark1);
}

.bg-theme-darker2 {
    background-color: var(--bs-primary-dark2);
}

.bg-theme-darker3 {
    background-color: var(--bs-primary-dark3);
}

.bg-theme-darker4 {
    background-color: var(--bs-primary-dark4);
}

.bg-theme-lighter {
    background-color: var(--bs-primary-light1);
}

.bg-theme-lighter2 {
    background-color: var(--bs-primary-light2);
}

.bg-theme-lighter3 {
    background-color: var(--bs-primary-light3);
}

.bg-theme-lighter4 {
    background-color: var(--bs-primary-light4);
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.border-blue {
    border-color: var(--bs-blue) !important;
}

.border-purple {
    border-color: var(--bs-purple) !important;
}

.border-green {
    border-color: var(--bs-green) !important;
}

.border-orange {
    border-color: var(--bs-orange) !important;
}

.border-yellow {
    border-color: var(--bs-yellow) !important;
}

.border-red {
    border-color: var(--bs-red) !important;
}

.border-pink {
    border-color: var(--bs-pink) !important;
}

.border-indigo {
    border-color: var(--bs-indigo) !important;
}

.border-gray-300 {
    border-color: var(--bs-gray-300) !important;
}

.border-gray {
    border-color: var(--bs-gray-600) !important;
}

.border-default {
    border-color: black !important;
}

.theme-dark .border-default {
    border-color: white !important;
}

// Font Weights
// ——————————————————————————————————————————

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

// Cursors
// ——————————————————————————————————————————

.cursor-default {
    cursor: default;
}

.cursor-pointer,
.is-clickable {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}



.fs-1 {
    font-size: 1rem !important;
}

.fs-1-2 {
    font-size: 1.2rem;
}

.badge {
    font-weight: 400;
}