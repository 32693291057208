.react-json-view {
    background-color: #222222 !important;
    border: 1px solid #515151;
    padding: 0.375rem 0.75rem;
    border-radius: 0.375rem;

    .node-ellipsis,
    .collapsed-icon>svg,
    .expanded-icon>svg,
    .variable-value,
    .variable-value>div {
        color: var(--theme-color) !important;
    }
}